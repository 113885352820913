.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  background-color: white;
}

.loader-container {
  width: 96rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader-image {
  width: 100px;
  margin: auto;
  animation: spin 1s linear infinite;
}

.loader-text {
  text-align: center;
  padding-top: 10px;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  color: black;
  font-weight: 400;
}
