.twoFactorAuthContainer {
  padding-top: 5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.IconEmbedd{
 
  display: flex ;
}

.IconEmbedd:last-child {
  margin-left: 20px;
}
.authCard {
  background-color: #F8F8F8;
  margin-left: auto;
  margin-right: auto;
  border-radius: 1rem;
  padding: 1rem;
}

.authCardContent {
  max-width: 450px;
  padding: 1rem;
}

.authImage {
  max-width: 50px;
}

.authTitle {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.authDescription {
  font-size: 0.75rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
}

.otpInputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.smsButtonWrapper {
  margin-top: 1.5rem;
  margin-left: 21.5rem;
  width: 200px;

}

.notMyNumberButton {
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.verificationContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 5rem;
}


.verificationText {
  font-size: 0.75rem;
  font-weight: 500;
  width: 75%;
}

.methodButtons {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.clickableTextContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;  
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: 0;
  width: 2000px;
}

.icon {
  width: 24px;  
  height: 24px; 
}



.clickableText {
  margin-left: 1rem;
  color: #2267A5;
  cursor: pointer;
  text-decoration: underline;
}
.clickableTextContainer :nth-child(3) {
  margin-left: 1rem; 
}
.clickableTextContainer :nth-child(4) {
  margin-left: 1rem; 
}
@media only screen and (max-width: 840px) {


  .go4109123758{
    width: 100% !important;
      display: center !important;
      position: absolute;
      transition: all 230ms cubic-bezier(0.21, 1.02, 0.73, 1) 0s;
      transform: translateY(0px);
      top: -500px;
      justify-content: center !important;
      align-self: center !important;
      text-align: start !important;
  }

  .b strong {
  font-size: 12px !important;
  align-self: flex-start;
  align-content: flex-start;
  margin: 0;
  text-align: start !important;
  }

  .go3958317564{
    align-items: flex-start !important;
    align-self: flex-start !important;
    align-content: flex-start;
    text-align: start !important;
  }

  .go2072408551 {
    width: 100vw !important;
      font-size: 12px !important;
      top: 0 !important;    
      align-items: flex-start !important;
      align-content: flex-start !important;
      align-self: center !important;
      text-align: start !important;
    }
  

  .twoFactorAuthContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh; 
      padding-top: 5rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    
    .authCard {
      background-color: #F8F8F8;
      margin: 0 auto; 
      border-radius: 1rem;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .authCardContent {
      max-width: 450px;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    .authImage {
      max-width: 50px;
    }
    
    .authTitle {
      padding-top: 1rem;
      padding-bottom: 0.5rem;
      font-size: 1.5rem;
      font-weight: 600;
      text-align: center;
    }
    
    .authDescription {
      font-size: 0.75rem;
      font-weight: 500;
      margin-bottom: 1.5rem;
      text-align: center;
    }
    
    .otpInputWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    
    .smsButtonWrapper {
      margin-top: 1.5rem;
      /* margin-left: auto;
      margin-right: auto; */
      margin: 0;
      margin-top: 1.5rem;
      width: 200px;
      align-items: center;
      align-self: center;
      align-content: center;
      display: inline-block;
     
  }
  .parent {
      text-align: center;
  }
    
    .notMyNumberButton {
      font-size: 0.75rem;
      font-weight: 600;
      margin-bottom: 0.75rem;
      text-align: center;
    }
    
    .verificationContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 5rem;
    }
    
    .verificationText {
      font-size: 0.75rem;
      font-weight: 500;
      width: 75%;
      text-align: center;
    }
    
    .methodButtons {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
    }
    
    .clickableTextContainer {
      display: flex;
      justify-content: center;
      align-items: center;  
      margin-bottom: 1rem;
      margin-top: 1rem;
      margin-left: 0;
      width: 100%;
    }
    
    .icon {
      width: 24px;  
      height: 24px; 
    }
    
    .clickableText {
      margin-left: 1rem;
      color: #2267A5;
      cursor: pointer;
      text-decoration: underline;
    }
    
    .clickableTextContainer :nth-child(3) {
      margin-left: 1rem; 
    }
    
    .clickableTextContainer :nth-child(4) {
      margin-left: 1rem; 
    }
    
}
